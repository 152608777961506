import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import App from '../../App';
import Hero from '../../components/Hero';
import ContactSection from '../../components/ContactSection';
import TechInfo from '../../components/TechInfo';
import MiniQuotationSection from '../../components/QuotationSection/MiniQuotationSection';
import Container from '../../components/Container';
import BlueDotsIcon from '../../assets/icons/BlueDotsIcon';
import SaasStoryPageHeroImage from '../../gatsbyImages/SaasStoryPageHeroImage';
import SaasStoryMockupImage from '../../gatsbyImages/SaasStoryMockupImage';
import SaasStoryMockup2Image from '../../gatsbyImages/SaasStoryMockup2Image';
import SaasStoryMockup3Image from '../../gatsbyImages/SaasStoryMockup3Image';
import styles from './projectPages.module.scss';

const messages = defineMessages({
  title: {
    id: 'saasStory.title',
    defaultMessage: 'Leading SaaS video editor'
  },
  description: {
    id: 'saasStory.description',
    defaultMessage:
      'Location: New York City {br} Project Length: 1 year, ongoing {br}'
  },
  contactSectionTitle: {
    id: 'saasStory.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'saasStory.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  },
  techUsed: {
    id: 'saasStory.techUsed',
    defaultMessage: 'Tech used: '
  },
  react: {
    id: 'saasStory.react',
    defaultMessage: 'React'
  },
  typeScript: {
    id: 'saasStory.java',
    defaultMessage: 'Typescript'
  },
  firstSectionTitle: {
    id: 'saasStory.firstSectionTitle',
    defaultMessage:
      'HOW STREAMBRIGHT HELPED A NICHE VIDEO EDITOR BECAME THE MARKET LEADER'
  },
  miniQuotationDescription1: {
    id: 'saasStory.miniQuotationDescription1',
    defaultMessage: `Streambright helped our staff to grow seamlessly. We had our core dev team working days and nights to make our application amazing but we needed more resources. Hiring in a major tech-hub in the States is just incredibly expensive and tough in today's market. Dev's from Streambright were able to hit the ground running and contribute from day one. We were amazed!`
  },
  firstSectionDescription1: {
    id: 'saasStory.firstSectionDescription1',
    defaultMessage:
      'Our client provides a leading video editing app that makes creation easy: bring your audio, video, or ideas. Add animations, captions, gifs, videos, images and more. Export your video and share across your social accounts. You can even transcribe it automatically or create text animations.'
  },
  firstSectionDescription2: {
    id: 'saasStory.firstSectionDescription2',
    defaultMessage: `They burned through multiple partnerships in the past, where the pain point was always the inability of the outsourcing partner to catch up with their release management flows and inner structure of their stack. Most importantly they were struggling to find a partner which can provide resources who they can handle as part of their own team, and who can comply with their code quality conduct.`
  },
  firstSectionDescription3: {
    id: 'saasStory.firstSectionDescription3',
    defaultMessage: `Managing past contractors required heavy involvement, taking a lot of precious time from their key focus - the product. After on-boarding, we got familiar with the software and vision then we jumped straight into work. After a few weeks it was undeniable that we had the chemistry, so we deepened our relationship and continue to do so since then.`
  },
  miniQuotationDescription2: {
    id: 'saasStory.miniQuotationDescription2',
    defaultMessage:
      'The development services Streambright provided, helped our client to better serve their clients. They are able to progress faster with their roadmap and to focus on their core business - providing a world class video editing software that also drives engagement for your content.'
  }
});

function SaasStory() {
  return (
    <App>
      <Helmet>
        <title>Streambright - Case Study: SaaS Case Study</title>
        <meta
          name="description"
          content="How StreamBright took a leading video editor from popular hit to standout success."
        />
      </Helmet>
      <Hero
        icon={<BlueDotsIcon />}
        title={<FormattedMessage {...messages.title} />}
        description={
          <>
            <FormattedMessage
              {...messages.description}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.typeScript} />
            </div>
          </>
        }
        backgroundImage={SaasStoryPageHeroImage}
        image={SaasStoryMockupImage}
      />
      <Container>
        <div className={styles.title}>
          <FormattedMessage {...messages.firstSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription1} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription1}
        />
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription2} />
        </div>
        <div className={cn(styles.imageContainer, styles.mockupImageContainer)}>
          <SaasStoryMockup2Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <SaasStoryMockup3Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <div className={styles.topGreenIcon}>
            <BlueDotsIcon />
          </div>
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription3} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription2}
        />
      </Container>
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default SaasStory;
